import modifypassword from '@index/components/modifypassword.vue';
import common from '@index/utils/common';
import {
	Message
} from 'element-ui'

import {
	handleQueryUserPublicMatchAuth
} from '@index/api/team/teamindex'
export default {
	name: 'teamteaminfo',
	components: {
		modifypassword
	},
	data() {
		return {
			domainInfo: {},
			matchDialogVisible: false,
			matchForm: {},
			tableData: [{
				id: 'A1588',
				name: '“舞动时代华章  献礼建党百年”2021中国·凉山第三届国际标准舞（体育舞蹈）锦标赛'
			}]
		};
	},
	watch: {

	},
	created() {
		// window.addEventListener('storage', this.afterQRScan)
		if (sessionStorage.getItem("userPasswrodNullFlag") == 'YES') {
			this.openSetPasswd(); //打开密码修改框
		}
	},
	mounted() {
		this.init();
	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		init() {
			var temp = sessionStorage.getItem('domainInfo');
			this.domainInfo = temp ? JSON.parse(temp) : {
				schemeName: '我是记分长'
			};
			if (this.domainInfo) {
				document.title = this.domainInfo.schemeName;
			}
		},
		modify() {
			this.$data.matchDialogVisible = true;
		},
		handleMenusSelect() {

		},
		toIndexPage() {
			handleQueryUserPublicMatchAuth().then(res => {
				if (res.status == 200) {
					if (res.data.authStatus == '2' || res.data.authStatus == '1') { //允许发布赛事（授权比赛）
						this.$router.push({
							path: '/transitionpage'
						})
					} else { //不允许发布赛事
						this.$router.push({
							path: '/team'
						})
					}

				} else {
					Message({
						type: "error",
						message: res.msg,
						duration: 5000
					});
				}
			});
		},
		headderCommand(command) {
			switch (command) {
				case "modifyPassword":
					this.$refs.modifypassword.openDialog();
					break;
				case "out":
					common.signout();
					break;
			}
		},
		toHelp() {
			console.log(11);
			window.open('help.html')
		}

	}
}